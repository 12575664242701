import {
    Grid,
    Paper,
    Theme,
    Typography,
    withStyles,
    Button
} from "@material-ui/core"
import {List, ListItem, ListItemText} from "@material-ui/core"
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core"
import React, {useState, useEffect} from "react"
import {API} from 'aws-amplify'
import {Connect} from 'aws-amplify-react'
import * as queries from './graphql/queries'
import idx from "idx"
import Devices from "./Devices"
import NotificationSettings from "./NotificationSettings"
import moment from 'moment'

const styles = (theme: Theme) => ({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        overflow: "auto"
    },
    unassignedPetRow: {
        backgroundColor: theme.palette.background.default
    }
});

interface Props {
    user: User,
    classes: {
        paper: string,
        unassignedPetRow: string
    }
}

export const UserView = withStyles(styles)(({classes, user}: Props) => {
    const [activeRequest, setActiveRequest] = useState()
    const [administrationTimeout, setAdministrationTimeout] = useState()
    const refreshAdministrationRequest = (r: any) => {
        setActiveRequest(r)
        administrationTimeout && clearTimeout(administrationTimeout)
        if (r) {
            setAdministrationTimeout(setTimeout(() => setActiveRequest(null), moment(r.ExpiresAt).diff(moment())))
        }
    }
    const Item: React.FunctionComponent<any> = ({children, ...props}) =>
        <Grid item xs={12} sm={6} xl={3} {...props}>
            <Paper className={classes.paper}>{children}</Paper>
        </Grid>
    const readonly = !(activeRequest && activeRequest.FulfilledAt)

    useEffect(() => {
        API.graphql({query: queries.getAdministrationRequests, variables: {}})
        //@ts-ignore
           .then((result: any) => {
               const request = (result.data.getAdministrationRequests || []).find((r: any) => r.Target == user.sub && moment(r.ExpiresAt).isAfter(moment()))
               refreshAdministrationRequest(request)
           })

        return () => administrationTimeout && clearTimeout(administrationTimeout)
    }, [user])

    const deviceHash: {[id: string]: Device} = user.devices.reduce((o, d) => ({...o, [d.internalId]: d}), {})
    const petHash: {[id: string]: Pet} = user.pets.reduce((o, p) => ({...o, [p.id]: p}), {})

    return <Grid container spacing={6}>
        <Item>
            <Typography variant="h6">User</Typography>
            <RequestAdministrationAccess user={user.sub} things={user.devices.map(d => d.id)}
                onRequestFulfilled={refreshAdministrationRequest}
                activeRequest={activeRequest}/>
            <List>
                <ListItem>
                    <ListItemText primary="Email" secondary={user.email}/>
                    <ListItemText primary="Username" secondary={user.username}/>
                </ListItem>
            </List>
        </Item>
        <Item>
            <NotificationSettings settings={user.settings.notifications}
                user={user}
                readonly={readonly}/>
        </Item>
        <Item>
            <Typography variant="h6">Pets</Typography>
            <Table>
                <TableHead><TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Race</TableCell>
                    <TableCell>Assigned to Doors</TableCell>
                </TableRow></TableHead>
                <TableBody>{user.pets.map(renderPet)}</TableBody>
            </Table>
        </Item>
        <Grid item xs={12}>
            <Devices devices={user.devices
                .map(d => ({
                    ...d,
                    rfid_store: d.rfid_store.map(s => ({...s, assigned_pet: idx(petHash, _ => _[s.assigned_pet].name)!}))
                }))}
                allPets={user.pets}
                user={user}
                readonly={readonly}
            />
        </Grid>
    </Grid>

    function renderPet(p: Pet) {
        const doorAssignments = p.assigned_to_doors
            .map(id => idx(deviceHash, _ => _[id].caption))
            .filter(c => !!c)
        const cls = !doorAssignments.length ? classes.unassignedPetRow : undefined
        return <TableRow key={p.name} className={cls}>
            <TableCell>{p.name}</TableCell>
            <TableCell>{p.race}</TableCell>
            <TableCell>{doorAssignments.join(", ")}</TableCell>
        </TableRow>
    }
})

interface GetUserResponse {
    data: {
        getUser: User
    },
    loading: boolean,
    errors: Array<string>
}

export default (props: {email: string}) =>
    <Connect query={queries.getUser(props.email)}>
        {({ data, loading, errors }: GetUserResponse) => {
            if (errors && errors.length) return (<h3>Error</h3>)
            if (loading || !data.getUser) return (<h3>Loading...</h3>)
            return <UserView user={data.getUser}/>
        }}
    </Connect>

const RequestAdministrationAccess = (props: any) => {
    return <Connect mutation={{query: queries.requestAdministrationAccess, variables:{}}}>
    {({mutation}: any) => {
        return <RequestAdministrationAccess_
            onRequestFulfilled={props.onRequestFulfilled}
            activeRequest={props.activeRequest}
            onClick={() => mutation({
                things: props.things,
                target: props.user
            })}/>
    }}
    </Connect>
}
const RequestAdministrationAccess_ = (props: any) => {
    const [fetchingTimeout, setFetchingTimeout] = useState()
    const now = moment()

    const refetch = async () => {
        const result: any = await API.graphql(queries.getAdministrationRequest(props.activeRequest.RequestId))
        if (!result.data.getAdministrationRequest.FulfilledAt)
            setFetchingTimeout(setTimeout(refetch, 2000))
        else
            props.onRequestFulfilled(result.data.getAdministrationRequest)
    }
    useEffect(() => {
        if (props.activeRequest && !props.activeRequest.FulfilledAt)
            refetch()

        return () => fetchingTimeout && clearTimeout(fetchingTimeout)
    }, [props.activeRequest])

    if (props.activeRequest)
        return <div>
            <span>
                {props.activeRequest.FulfilledAt
                ? 'Administration access active.'
                : 'Administration access pending.'}
            </span>&nbsp;
            <span>
                Expiring at: {moment(props.activeRequest.ExpiresAt).format('YYYY-MM-DD hh:mm:ss')}
            </span>
        </div>
    else {
        return <Button
                   onClick={async () => {
                       const newRequest = await props.onClick()
                       console.log(newRequest)
                       props.onRequestFulfilled(newRequest.data.requestAdministrationAccess)
                   }}
                   variant="contained">
            Request administration access
        </Button>
    }
}
