import React, {FormEvent, ReactElement, useState} from 'react'
import {Button, FormControl, FormGroup, Grid, TextField} from "@material-ui/core"
import {Redirect} from "react-router-dom"
import {API} from 'aws-amplify'
import {resolveToUserEmail} from './graphql/queries'

export default () => {
    const [text, setText] = useState("")
    const [redirect, setRedirect] = useState<ReactElement | undefined>(undefined)

    async function onFormSubmitted(e: FormEvent) {
        e.preventDefault()
        if (isEmail(text)) setRedirect(<Redirect to={`/users/${text}`}/>)
        else {
            const result: any = await API.graphql(resolveToUserEmail({device_serial: text}))
            setRedirect(<Redirect to={`/users/${result.data.resolveToUserEmail}`}/>)
        }
    }

    if (redirect) return redirect

    // @ts-ignore
    return <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{height: "500px"}}
    >
        <form onSubmit={onFormSubmitted}><Grid item><FormGroup row>
            <TextField
                id="outlined-name"
                label="Email or Serial Number"
                value={text}
                onChange={e => setText(e.target.value)}
                margin="normal"
                variant="outlined"
                style={{width: "500px"}}
            />
            <FormControl margin="normal" style={{paddingLeft: "10px"}}>
                <Button type="submit" variant="contained" size="large" color="primary" style={{height: "100%"}}>Search</Button>
            </FormControl>
        </FormGroup></Grid></form>
    </Grid>
}

const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
function isEmail(text: string) {
    return emailRegex.test(text)
}
