// Workaround for https://github.com/mui-org/material-ui/issues/6098
import {withStyles} from "@material-ui/core"
import React from "react"

export default withStyles(
    theme => ({
        root: {margin: '-12px 4px -12px -12px'},
    }),
)(
    (props: any) => (
        <div className={props.classes.root}>
            {props.children}
        </div>
    ),
);