import React, {useState} from 'react'
import {Select, Chip, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, withStyles, IconButton, TextField} from "@material-ui/core"
import {KeyboardTimePicker} from "@material-ui/pickers"
import {Edit as EditIcon, Done as DoneIcon, Clear as ClearIcon} from "@material-ui/icons"
import {resolveShadowValue, resolveSource, translateShadowKey, replaceShadowValue} from "../shadow"
import {PubSub} from "aws-amplify"
import moment from 'moment'
import {flags, splitFlags} from './flags'

interface Props {
    shadow: any,
    readonly: boolean,
    thingName: string
}
export default (props: Props) => {
    return <>
        <Typography variant="h6">Door Configuration</Typography>
        <Table>
            <TableHead><TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Source</TableCell>
                {!props.readonly && <TableCell/>}
            </TableRow></TableHead>
            <TableBody>
    {Object.keys(props.shadow.reported).sort((a, b) => {
        const ta = translateShadowKey(a)
        const tb = translateShadowKey(b)

        if (ta < tb) return -1
        if (ta > tb) return 1
        return 0
    }).map(k =>
                    <ShadowEntry
                        key={k}
                        k={k}
                        thingName={props.thingName}
                        value={props.shadow.reported[k]}
                        readonly={props.readonly}/>
                )}
            </TableBody>
        </Table>
    </>
}

const ShadowEntry = withStyles((theme: Theme) => ({
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    formRow: {
        maxWidth: "180px"
    },
}))((props: any) => {
    const [edit, setEdit] = useState(false)
    const [text, setText] = useState(resolveShadowValue(props.value, props.k))

    const updateShadow = async () => {
        await PubSub.publish(`$aws/things/${props.thingName}/shadow/update`, {
            state: {desired: {[props.k]: replaceShadowValue(text, props.value)}}
        }, { provider: 'AWSIoTProvider' })
        setEdit(false)
    }
    const onUpdateCanceled = () => {
        setEdit(false)
        setText(resolveShadowValue(props.value, props.k))
    }

    const Component = getShadowValueComponent(props.k)

    return <TableRow className={props.classes.tableRow}>
        <TableCell>{translateShadowKey(props.k)}</TableCell>
        <TableCell className={props.classes.formRow}>
            <Component value={text} onChange={setText} disabled={!edit} />
        </TableCell>
        <TableCell>{resolveSource(props.value)}</TableCell>
        {!props.readonly && !edit && !editBlacklist.includes(props.k) &&
         <TableCell style={actionStyle}>
             <IconButton onClick={() => setEdit(true)}><EditIcon/></IconButton>
         </TableCell>}
        {!props.readonly && edit && !editBlacklist.includes(props.k) &&
        <TableCell style={actionStyle}>
            <div style={{display: 'inline-flex'}}>
                <IconButton onClick={updateShadow}><DoneIcon/></IconButton>
                <IconButton onClick={onUpdateCanceled}><ClearIcon/></IconButton>
            </div>
        </TableCell>}
    </TableRow>
})

const actionStyle = {
    paddingLeft: "0",
    paddingRight: "0"
}

const editBlacklist = [
    'rfid_configuration',
    'isConnected',
    'clb_state_ip',
    'clb_state_version',
    'clb_state_version_lgcy',
    'clb_cfg_petSetting',
    'clb_state_sense_darkness',
    'clb_state_sense_rain',
    'clb_state_sense_raining',
    'clb_cfg_battery',
    'clb_state_power',
    'clb_state_error'
]

export function getShadowValueComponent(key: string) {
    return shadowValueComponentMap[key] || PlainShadowValue
}

type ShadowComponent = {
    value: any,
    onChange: (v: any) => void,
    disabled?: boolean
}
const ShadowValue = (Component: React.ComponentType<ShadowComponent>, attributes: object) => (props: ShadowComponent) =>
    <Component value={props.value} onChange={props.onChange} disabled={props.disabled} {...attributes}/>

const PlainShadowValue = (props: any) => <TextField value={props.value} onChange={e => props.onChange(e.target.value)} disabled={props.disabled}/>
const DropdownShadowValue = (props: any) =>
    <Select value={props.value} onChange={e => props.onChange(e.target.value)} disabled={props.disabled}>
        {props.items.map((i: any) =>
            <MenuItem key={i.value} value={i.value}>{i.display}</MenuItem>)}
    </Select>
const NumberShadowValue = (props: any) =>
    <TextField type="number" value={props.value} onChange={e => props.onChange(e.target.value)} disabled={props.disabled}
        inputProps={{ min: props.min || 0, max: props.max, step: props.step || 1}}/>
const DurationShadowValue = (props: any) =>
    <KeyboardTimePicker
        ampm={false}
        variant="inline"
        disabled={props.disabled}
        value={moment.utc(props.value * 60 * 1000)}
    onChange={d => d && props.onChange(d.valueOf() / 60 / 1000)}/>

const shadowValueComponentMap: {[key: string]: React.ComponentType<ShadowComponent>} = {
    'clb_state_power': ShadowValue(DropdownShadowValue, {
        items: [{value: 'mains', display: 'Mains'}, {value: 'battery', display: 'Battery'}]
    }),
    'clb_cfg_sens_rain': ShadowValue(NumberShadowValue, {min: 0, max: 99}),
    'clb_cfg_sens_out': ShadowValue(NumberShadowValue, {min: 0, max: 99}),
    'clb_cfg_sens_in': ShadowValue(NumberShadowValue, {min: 0, max: 99}),
    'clb_cfg_volume': ShadowValue(NumberShadowValue, {min: 0, max: 4}),
    'clb_delivery_open': ShadowValue(NumberShadowValue, {min: 1}), // deprecated
    'clb_cfg_brightness': ShadowValue(NumberShadowValue, {min: 0, max: 99}),
    'clb_cfg_open_time': ShadowValue(NumberShadowValue, {min: 2, max: 99}),
    'clb_cfg_timezone': ShadowValue(NumberShadowValue, {min: -11, max: 12}),
    'clb_cfg_did': ShadowValue(DropdownShadowValue, {
        items: [{value: 'off', display: 'Off'}, {value: 'n_open', display: 'n_open'}, {value: 'n_closed', display: 'n_closed'}]
    }),
    'clb_cfg_time_mode': ShadowValue(DropdownShadowValue, {
        items: [{value: 12, display: '0-12'}, {value: 24, display: '0-24'}]
    }),
    'clb_cfg_led_brightness': ShadowValue(NumberShadowValue, {min: 0, max: 99}),
    'clb_cfg_battery': ShadowValue(DropdownShadowValue, {
        items: [{value: 0, display: 'not installed'}, {value: 1, display: 'installed'}]
    }),
    'clb_cfg_time_in_on': ShadowValue(DurationShadowValue, {}),
    'clb_cfg_time_out_on': ShadowValue(DurationShadowValue, {}),
    'clb_cfg_time_in_off': ShadowValue(DurationShadowValue, {}),
    'clb_cfg_time_out_off': ShadowValue(DurationShadowValue, {}),
    'clb_cfg_door_angle': ShadowValue(NumberShadowValue, {min: 0, max: 99}),
    'clb_state_door_pos': ShadowValue(DropdownShadowValue, {
        items: [{value: 'open', display: 'Open'}, {value: 'closed', display: 'Closed'}] // TODO: Show but disable error state
    }),
    'clb_state_opmode': ShadowValue(DropdownShadowValue, {
        items: [{value: 'off', display: 'Off'}, {value: 'on', display: 'On'}] // TODO: Show but disable error state
    }),
    //@ts-ignore
    'clb_cfg_flags': ShadowValue(withStyles((theme: Theme) => ({
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        }
    }))((props: any) =>
        <Select value={splitFlags(props.value)}
                onChange={(e: any) => props.onChange(e.target.value.reduce((a: number, b: number) => a + b, 0))}
                disabled={props.disabled}
                renderValue={selected =>
                    <div className={props.classes.chips}>
                        {(selected as number[]).map((value: number) =>
                            <Chip key={value} label={flags[value]} className={props.classes.chip} />
                        )}
                    </div>}
                multiple>
            {Object.keys(flags).map((k: any) =>
                <MenuItem key={k} value={parseInt(k)}>{flags[k]}</MenuItem>)}
        </Select>), {})
}
