export const translateShadowKey = (key: string) => shadowKeyMap[key] || key
export const resolveShadowValue = (v: any, k: string) => {
    if (v === null || v === undefined) return ''

    switch (k) {
        case 'clb_state_error':
            return `${v.value}: ${v.message}`
        default:
            if (typeof(v) === 'string') return v
            if (typeof(v) === 'object') return v.value
            if (typeof(v) === 'boolean') return v === true ? 'true' : 'false'
            if (typeof(v) === 'number') return v
            return `${v}`
    }
}
export const resolveSource = (v: any) => {
    if (v !== null && typeof(v) === 'object' && v.source) return v.source
    else return ''
}
export const replaceShadowValue = (v: any, original: any) => {
    if (typeof(original) === 'object') {
        return {
            ...original,
            value: v,
            source: 'SUPPORT'
        }
    } else {
        return v
    }
}

const shadowKeyMap: {[key: string]: string} = {
    clb_state_opmode: 'Operation Mode',
    clb_cfg_flags: 'Configuration Flags',
    clb_cfg_time_in_off: 'Time In Off',
    clb_cfg_time_in_on: 'Time In On',
    clb_cfg_time_out_on: 'Time Out On',
    clb_cfg_time_out_off: 'Time Out Off',
    isConnected: 'Is Connected',
    clb_state_door_pos: 'Door State',
    clb_cfg_timezone: 'Timezone',
    clb_state_power: "Power Source",
    clb_state_error: "Error",
    clb_state_ip: "IP",
    clb_state_alarm: "Alarm",
    clb_cfg_volume: "Volume",
    clb_cfg_time_mode: "Time Mode",
    clb_state_version_lgcy: "Legacy Version Field",
    clb_state_sense_raining: "Raining Sense",
    clb_cfg_sens_rain: "Rain Sense",
    clb_cfg_sens_out: "Sense Out",
    clb_cfg_sens_in: "Sense In",
    clb_cfg_petSetting: "RFID Configuration",
    clb_cfg_open_time: "Time Open",
    clb_cfg_led_brightness: "LED Brightness",
    clb_cfg_door_angle: "Door Angle",
    clb_cfg_did: "Door in Door Contact",
    clb_cfg_brightness: "Brightness",
    clb_cfg_battery: "Battery",
    clb_state_version: "Version"
}
