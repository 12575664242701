import React, {useState} from 'react'
import {
    List,
    ListItem, ListItemText,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow, Theme,
    Typography, withStyles
} from "@material-ui/core"
import moment from "moment"
import {resolveShadowValue, translateShadowKey, resolveSource} from "../shadow"
import {flags, splitFlags} from './flags'

interface Props {
    data: Array<{delta: string, timestamp: number}>,
    classes: {
        tableRow: string
    }
}
export default withStyles((theme: Theme) => ({
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    }
}))((props: Props) => {
    const [page, setPage] = useState(0)

    return <>
        <Typography variant="h6">Event History</Typography>
        <TablePagination
            rowsPerPage={30}
            rowsPerPageOptions={[30]}
            component="div"
            count={props.data.length}
            page={page}
            onChangePage={(ev, pg) => setPage(pg)}/>
        <Table>
            <TableHead><TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>Changes</TableCell>
            </TableRow></TableHead>
            <TableBody>
                {props.data.slice(page * 30, page * 30 + 30).map(ev => {
                    const delta = JSON.parse(ev.delta)
                    return <TableRow key={ev.timestamp} className={props.classes.tableRow}>
                        <TableCell>{moment.utc(ev.timestamp).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell><List dense>
                            {Object.keys(delta).map(d => <ListItem key={`${ev.timestamp}${d}`}>
                                <ListItemText primary={`${translateShadowKey(d)} - ${resolveSource(delta[d])}`}
                                              secondary={translateShadowValue(resolveShadowValue(delta[d], d), d)}/>
                            </ListItem>)}
                        </List></TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
})

const translateShadowValue = (val: any, key: string) => {
    switch (key) {
        case 'clb_cfg_flags':
            return splitFlags(val).map(v => flags[v]).join(', ')
        case 'clb_cfg_battery':
            return val == 0 ? 'not installed' : 'installed'
        default:
            return val
    }
}
