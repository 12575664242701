import {GraphQLOptions} from "@aws-amplify/api/lib/types"

// language=GraphQL
export const getUser = (email: string): GraphQLOptions => ({
    query: `
        query GetUser($email: String) {
          getUser(email: $email) {
            sub
            email
            username
            devices {
              id
              internalId
              caption
              serial
              type
              is_alexa_controlled
              version
              connected
              shadow
              rfid_store {
                in
                out
                assigned_pet
              }
              eventData {
                delta
                timestamp
              }
            }
            pets {
              id
              name
              race
              assigned_to_doors
            }
            settings {
              notifications {
                key
                value
              }
            }
          }
        }
        `,
    variables: {email}
})

export const getAdministrationRequests = `
query GetAdministrationRequests {
  getAdministrationRequests {
    RequestId
    UserId
    ExpiresAt
    FulfilledAt
    Target
  }
}`

export const getAdministrationRequest = (id: string) =>
    ({
        query: `
query GetAdministrationRequest($id: String) {
  getAdministrationRequest(id: $id) {
    RequestId
    ExpiresAt
    FulfilledAt
    Target
  }
}
`,
        variables: {id}
    })

export const requestAdministrationAccess = `
mutation RequestAdministrationAccess($target: String, $things: [String]) {
  requestAdministrationAccess(targetUserSub: $target, things: $things) {
    RequestId
    ExpiresAt
    Target
  }
}`

export const assignPet = (user: string, petId: number, deviceId: number, storageIndex: number) => ({
    query: `
mutation AssignPet($user: String, $petId: Int, $doorId: Int, $storageIndex: Int) {
  assignPet(petId: $petId, doorId: $doorId, storageIndex: $storageIndex, userSub: $user)
}`,
    variables: {petId, storageIndex, user, doorId: deviceId}
})

export const unassignPet = (user: string, petId: number, deviceId: number) => ({
    query: `
mutation UnassignPet($user: String, $petId: Int, $doorId: Int) {
  unassignPet(petId: $petId, doorId: $doorId, userSub: $user)
}`,
    variables: {petId, user, doorId: deviceId}
})

export const updateNotificationSettings = (user: string, settings: object) => ({
    query: `
mutation UpdateNotificationSettings($user: String, $settings: AWSJSON) {
  updateNotificationSettings(userSub: $user, settings: $settings)
}`,
    variables: {user, settings: JSON.stringify(settings)}
})

export const resolveToUserEmail = (query: any) => ({
    query: `
query ResolveToUserEmail($query: AWSJSON) {
  resolveToUserEmail(query: $query)
}`,
    variables: {query: JSON.stringify(query)}
})
