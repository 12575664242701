import React from 'react'
import Amplify, {Auth} from 'aws-amplify'
import {withAuthenticator, Greetings, SignIn, RequireNewPassword, ForgotPassword} from 'aws-amplify-react'
import {AppBar, Button, IconButton, Toolbar, Typography} from "@material-ui/core"
import {Home} from '@material-ui/icons'
import {BrowserRouter as Router, Link, Route} from "react-router-dom"
import User from "./User"
import Search from "./Search"
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        mandatorySignIn: true,
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },

    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_IAM',
})

Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'eu-west-1',
    aws_pubsub_endpoint: process.env.REACT_APP_IOT_ENDPOINT
}))

export default withAuthenticator(() => {
    const LinkRoot = (props: any) => <Link to="/" {...props}/>

    function onLogoutClicked() {
        Auth.signOut().then(console.log).catch(console.error)
    }

    return <>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
            <AppBar position="static">
                <Toolbar>
                    <LinkRoot style={{textDecoration: "none", color: "inherit"}}><IconButton color="inherit"><Home/></IconButton></LinkRoot>
                    <Typography variant="h6" color="inherit" noWrap style={{flexGrow: 1, textDecoration: "none"}} component={LinkRoot}>
                        PetWALK Support Dashboard
                    </Typography>
                    <Button onClick={onLogoutClicked} color="inherit">Logout</Button>
                </Toolbar>
            </AppBar>
            <div style={{paddingTop: "1em"}}>
                <Route path="/" exact component={Search}/>
                <Route path="/users/:email" render={({match}) => <User email={match.params.email}/>}/>
            </div>
        </Router>
        </MuiPickersUtilsProvider>
    </>
}, {
    authenticatorComponents: [
        <Greetings/>,
        <SignIn/>,
        <RequireNewPassword/>,
        <ForgotPassword/>
    ]
})
