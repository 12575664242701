import React, {FunctionComponent, useState} from 'react'
import {Chip, Grid, List, ListItem, ListItemText, Tab, Tabs, Theme, Typography, withStyles} from "@material-ui/core"
import {Wifi, WifiOff} from "@material-ui/icons"
import idx from "idx"
import RfidConfiguration from "./RfidConfiguration"
import ShadowConfiguration from "./ShadowConfiguration"
import EventHistory from "./EventHistory"


interface Props {
    devices: Array<Device>,
    readonly: boolean,
    allPets: Array<Pet>,
    user: User,
    classes: {
        container: string,
        item: string,
        paper: string,
        tabBar: string
    }
}
export default withStyles((theme: Theme) => ({
    container: {
        paddingTop: theme.spacing(2),
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    item: {
        ...theme.mixins.gutters()
    },
    tabBar: {
        backgroundColor: theme.palette.background.default
    }
}))((props: Props) => {
    const [currentDevice, setCurrentDevice] = useState(props.devices[0])

    const TableGridItem: FunctionComponent = cprops =>
        <Grid item xs={12} sm={6} lg={4} className={props.classes.item}>{cprops.children}</Grid>
    const InformationGridItem: FunctionComponent = cprops =>
        <Grid item xs={6} md={3} className={props.classes.item}>{cprops.children}</Grid>

    return <>
        <Typography variant="h4">Devices</Typography>
        {props.devices.length > 1 &&
        <Tabs value={currentDevice.id}
              className={props.classes.tabBar}
              onChange={(ev, value) => setCurrentDevice(props.devices.find(d => d.id === value) as Device)}>
            {props.devices.map(d => <Tab key={d.id} value={d.id}
                                         label={<><p>{d.caption}</p><p>{d.serial}</p></>}/>)}
        </Tabs>
        }
        {currentDevice && <>
        <Grid container className={props.classes.container}>
            <Grid item xs={12} className={props.classes.item}>
                {currentDevice.is_alexa_controlled &&
                <Chip label="Alexa" color="primary"/>
                }
                <Chip label={currentDevice.connected ? 'Connected' : 'Disconnected'}
                      color={currentDevice.connected ? 'primary' : 'secondary'}
                      icon={currentDevice.connected ? <Wifi /> : <WifiOff/>}/>
            </Grid>
            <InformationGridItem>
                <Typography variant="h6">General Information</Typography>
                <List dense>
                    <ListItem>
                        <ListItemText primary="Caption" secondary={currentDevice.caption}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Serial Number" secondary={currentDevice.serial}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Version" secondary={currentDevice.version}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Type" secondary={currentDevice.type}/>
                    </ListItem>
                </List>
            </InformationGridItem>
        </Grid>
        <Grid container className={props.classes.container}>
            <TableGridItem>
                <RfidConfiguration data={currentDevice.rfid_store} allPets={props.allPets} readonly={props.readonly} device={currentDevice} user={props.user} />
            </TableGridItem>
            <TableGridItem>
                <ShadowConfiguration shadow={JSON.parse(idx(currentDevice, _ => _.shadow) || '{"reported":{}}')} readonly={props.readonly} thingName={currentDevice.id} />
            </TableGridItem>
            <TableGridItem>
                <EventHistory data={currentDevice.eventData}/>
            </TableGridItem>
        </Grid>
        </>}
    </>
})
