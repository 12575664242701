export const flags: {[key: number]: string} = {
    0x0001: 'Motion Sensor Out',
    0x0002: 'Motion Sensor In',
    0x0004: 'Mode: RFID',
    0x0008: 'Mode: Time',
    0x0010: 'Mode: Rain Sensor',
    0x0020: 'Mode: Brightness Sensor',
    0x0200: 'RFID In Disabled',
    0x0400: 'RFID Out Disabled',
    0x1000: 'Darkness In Disabled',
    0x2000: 'Darkness Out Disabled',
    0x4000: 'Rain In Disabled',
    0x8000: 'Rain Out Disabled'
}

export const splitFlags = (num: number) => {
    const max = 0x8000
    const flags: number[] = []

    for (let mask = 1; mask <= max; mask <<= 1) {
        (num & mask) != 0 && flags.push(num & mask)
    }

    return flags
}
