import React, {useState} from 'react'
import {Button, Checkbox, List, ListItem, ListItemText, Typography} from "@material-ui/core"
import DenserPrimaryAction from "./util/DenserPrimaryAction"
import {API} from 'aws-amplify'
import {updateNotificationSettings} from './graphql/queries'

export interface NotificationSetting {
    key: string,
    value: boolean
}

interface Props {
    user: User,
    settings: Array<NotificationSetting>,
    readonly: boolean
}
export default (props: Props) => {
    const [settings, setSettings] = useState(props.settings)

    const onSaveClicked = () => {
        API.graphql(updateNotificationSettings(props.user.sub, toDictionary(settings, o => o.key, o => o.value)))
        //@ts-ignore
           .then(result => {
               console.log(result)
           })
    }

    const onCheckboxChanged = (key: string) => (e: any) => {
        const idx = settings.findIndex(x => x.key === key)
        setSettings([...settings.slice(0, idx), {key, value: e.target.checked}, ...settings.slice(idx + 1)])
    }

    return <>
        <Typography variant="h6">Notification Settings</Typography>
        <List dense>
            {settings.map(({key, value}) => <ListItem key={key}>
                <DenserPrimaryAction><Checkbox checked={value} onChange={onCheckboxChanged(key)} disableRipple disabled={props.readonly} /></DenserPrimaryAction>
                <ListItemText primary={settingsKeyMap[key] || key} />
            </ListItem>)}
        </List>
        {!props.readonly &&
         <Button variant="contained" color="primary" onClick={onSaveClicked}>Save</Button>
        }
    </>
}

const settingsKeyMap: {[key: string]: string} = {
    opened_from_inside: 'Opened from the Inside',
    opened_from_outside: 'Opened from the Outside',
    device_error: 'Device Error',
    power_state_changed: 'Power State Changed',
    closed: 'Closed'
}

const toDictionary :<T, TNew>(array: T[], keyFunc: (o: T) => string, valueFunc: (o: T) => TNew) => {[key: string]: TNew}[] =
    (array, keyFunc, valueFunc) =>
        array.reduce((acc, cur) => ({...acc, [keyFunc(cur)]: valueFunc(cur)}), {}) as {[key: string]: any}[]
