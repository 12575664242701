import React, {useState} from 'react'
import {TextField, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, withStyles, IconButton, Select, MenuItem} from "@material-ui/core"
import {Edit as EditIcon, Done as DoneIcon, Clear as ClearIcon} from "@material-ui/icons"
import {API, PubSub} from 'aws-amplify'
import {assignPet, unassignPet} from '../graphql/queries'

interface Props {
    data: Array<{in: string, out: string, assigned_pet: string}>,
    readonly: boolean,
    device: Device,
    user: User,
    allPets: Array<Pet>,
}
export default (props: Props) => {
    const updateRfidConfiguration = (idx: number) => async (newConfig: {in: string, out: string}) => {
        await PubSub.publish(`$aws/things/${props.device.id}/shadow/update`, {
            state: {desired: {'pet_configuration':
                            JSON.stringify(
                                [...props.data.slice(0, idx),
                                newConfig,
                                ...props.data.slice(idx + 1)]
                                    .map(x => ([parseInt(x.in), parseInt(x.out)])))
            }}
        }, { provider: 'AWSIoTProvider' })
    }

    return <>
        <Typography variant="h6">RFID Store Configuration</Typography>
        <Table>
            <TableHead><TableRow>
                <TableCell>Index</TableCell>
                <TableCell>In</TableCell>
                <TableCell>Out</TableCell>
                <TableCell>Assigned Pet</TableCell>
            </TableRow></TableHead>
            <TableBody>
                {props.data.map((entry, idx) =>
                    <RfidEntry
                        entry={entry}
                        idx={idx}
                        key={idx}
                        readonly={props.readonly}
                        internalDeviceId={props.device.internalId}
                        allPets={props.allPets}
                        user={props.user}
                        onRfidConfigurationUpdated={updateRfidConfiguration(idx)}/>
                )}
            </TableBody>
        </Table>
    </>
}

const RfidEntry = withStyles((theme: Theme) => ({
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    }
}))((props: any) => {
    const [edit, setEdit] = useState(false)
    const [assignedPet, setAssignedPet] = useState(props.entry.assigned_pet && props.allPets.find((p:any) => p.name === props.entry.assigned_pet).id)
    const [inVal, setInVal] = useState(props.entry.in)
    const [outVal, setOutVal] = useState(props.entry.out)

    const onEntryChanged = async () => {
        await sendAssignedPet()
        await props.onRfidConfigurationUpdated({in: inVal, out: outVal})
        setEdit(false)
    }

    const sendAssignedPet = async () => {
        const originalPet = props.allPets.find((p:any) => p.name === props.entry.assigned_pet)
        if ((originalPet && originalPet.id) === assignedPet) return

        await assignedPet
            ? API.graphql(assignPet(props.user.sub, assignedPet, props.internalDeviceId, props.idx))
            : API.graphql(unassignPet(props.user.sub, originalPet.id, props.internalDeviceId))
    }

    return <TableRow className={props.classes.tableRow}>
        <TableCell>{props.idx}</TableCell>
        <TableCell>
            {edit
            ? <Select value={inVal} onChange={(e) => {setInVal(e.target.value)}}>
                <MenuItem value="0">default</MenuItem>
                <MenuItem value="1">prevent</MenuItem>
                <MenuItem value="2">allow</MenuItem>
            </Select>
            : mapRfidConfiguration(inVal)}
        </TableCell>
        <TableCell>
            {edit
            ? <Select value={outVal} onChange={(e) => {setOutVal(e.target.value)}}>
                <MenuItem value="0">default</MenuItem>
                <MenuItem value="1">prevent</MenuItem>
                <MenuItem value="2">allow</MenuItem>
            </Select>
            : mapRfidConfiguration(outVal)}
        </TableCell>
        <TableCell>
            {/* disabled */}
            {false && edit
            ? <Select
                  value={assignedPet || ""}
                  onChange={(e) => {setAssignedPet(e.target.value)}}>
                <MenuItem value="">None</MenuItem>
                {props.allPets.map((p :any) =>
                    <MenuItem value={p.id} key={p.id}>{p.name}</MenuItem>)}
              </Select>
            : assignedPet && props.allPets.find((p :any) => p.id === assignedPet).name
            }
        </TableCell>
        {!props.readonly && !edit &&
            <TableCell style={actionStyle}>
                <IconButton onClick={() => setEdit(true)}><EditIcon/></IconButton>
            </TableCell>}
        {!props.readonly && edit &&
            <TableCell style={actionStyle}>
                <div style={{display: 'inline-flex'}}>
                    <IconButton onClick={onEntryChanged}><DoneIcon/></IconButton>
                    <IconButton onClick={() => setEdit(false)}><ClearIcon/></IconButton>
                </div>
            </TableCell>}
    </TableRow>
})

const actionStyle = {
    paddingLeft: "0",
    paddingRight: "0"
}

const rfidConfigurationMap: {[key: number]: string} = {
    0: 'default',
    1: 'prevent',
    2: 'allow'
}
const mapRfidConfiguration = (value: number) => rfidConfigurationMap[value] || 'unknown'
